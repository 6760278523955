import React from 'react';
import ToggleSelector from '../components/general/ToggleSelector.js';
import PoolSportRow from '../components/tournament/PoolSportRow.js';
import { connect } from 'react-redux';
import { poolsData } from '../services/tournament/poolsActions.js';
import { Container, Row, Col } from 'react-bootstrap';
const d3 = require('d3-array');

/**
 * @class PoolsView
 * @classdesc Displays the pools for each category
 * @param {Object} props
 * @param {string[]} props.categories - list of categories
 * @param {Object} props.poolsByCategory - each key is a category, and the
 * corresponding value is a list of props for {@link PoolSportRow}
 * @returns {React.Component}
 */
class PoolsView extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedCategory: '',
			noData: false
		};

		this.selectCategory = this.selectCategory.bind(this);
	}

	componentDidMount() {
		this.props.getPoolsData().then(() => {
			if (Object.keys(this.props.poolsData).length === 0) {
				this.setState({ noData: true });
			} else {
				this.setState({
					selectedCategory: Object.keys(this.props.poolsData)[0]
				});
			}
		});
	}

	selectCategory(event) {
		this.setState({
			selectedCategory: event.target.innerHTML
		});
	}

	render() {
		if (this.state.noData) {
			return <p>No pools data</p>;
		} else if (this.props.poolsData) {
			let selectionObj = {
				selectedItem: this.state.selectedCategory,
				selectItem: this.selectCategory
			};

			let poolsRender = null;
			if (this.state.selectedCategory !== '') {
				poolsRender = [
					<Row>
						<Col></Col>
						<Col className="text-center">
							<h3>Single Pool</h3>
						</Col>
						<Col className="text-center">
							<h3>Pool A</h3>
						</Col>
						<Col className="text-center">
							<h3>Pool B</h3>
						</Col>
					</Row>
				];

				poolsRender.push(
					...this.props.poolsData[this.state.selectedCategory].map(
						element => (
							<PoolSportRow
								key={`${this.state.selectedCategory}${element.sport}`}
								{...element}
							/>
						)
					)
				);
			}

			return (
				<div>
					<ToggleSelector
						itemsList={Object.keys(this.props.poolsData)}
						selectionObj={selectionObj}
					/>
					<Container>{poolsRender}</Container>
				</div>
			);
		} else {
			return <p>Loading</p>;
		}
	}
}

const mapStateToProps = state => {
	let raw = state.pools;
	if (raw.data) {
		if (raw.data.length == 0) {
			return {
				poolsData: {}
			};
		}
		const groupedbyCat = Object.fromEntries(
			d3.group(
				raw.data,
				record => record.category,
				record => record.sport
			)
		);
		const output = {};
		const mapSportToPools = ([key, val]) => {
			return {
				sport: key,
				poolA: val.filter(x => x.pool === 'Pool A').map(x => x.team),
				poolB: val.filter(x => x.pool === 'Pool B').map(x => x.team),
				poolZero: val.filter(x => x.pool === 'Pool 0').map(x => x.team)
			};
		};
		for (const [key, val] of Object.entries(groupedbyCat)) {
			output[key] = Array.from(val, mapSportToPools);
		}
		return { poolsData: output };
	} else return { poolsData: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getPoolsData: () => dispatch(poolsData())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PoolsView);

// export const fakeprops = {
// 	categories: ["Ch D Male", "Ch E Female", "Jr A Male", "Jr B Male"],
// 	poolsByCategory: {
// 		'Ch E Female' : [
// 			{
// 				sport: 'Football',
// 				poolA: {
// 					poolTeams: [
// 						'Ch E Female ARG 1',
// 						'Ch E Female COL 2',
// 						'Ch E Female ISR 1',
// 						'Ch E Female VEN 2'
// 					]
// 				},
// 				poolB: {
// 					poolTeams: [
// 						'Ch E Female COL 1',
// 						'Ch E Female ISR 2',
// 						'Ch E Female USA 1',
// 						'Ch E Female VEN 1'
// 					]
// 				}
// 			},
// 			{
// 				sport: 'Basketball',
// 				poolA: {
// 					poolTeams: [
// 						'Ch E Female COL 1',
// 						'Ch E Female ISR 2',
// 						'Ch E Female USA 1',
// 						'Ch E Female VEN 1'
// 					]
// 				},
// 				poolB: {
// 					poolTeams: [
// 						'Ch E Female ARG 1',
// 						'Ch E Female COL 2',
// 						'Ch E Female ISR 1',
// 						'Ch E Female VEN 2'
// 					]
// 				}
// 			}
// 		],
// 		"Ch D Male": [],
// 		"Jr A Male": [],
// 		"Jr B Male": []
// 	}
// };
