import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card, Row, Col, ListGroup } from 'react-bootstrap';

/**
 * Selection screen for which categories an event will be made out to
 * @function UntieCard
 * @param {Object} props
 * @param {function} props.newEvent - function to prompt a new event creation
 * @returns {React.Component}
 */

export default function UntieCard(props) {
	if (props.edit) {
		return (
			<Card style={{ width: '18rem' }} className="mx-2 text-center">
				<Card.Body
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer'
					}}
				>
					<Col>
						<Card.Title className="p-2">
							{props.info[0].tournament}
						</Card.Title>
						<Row className="p-2">
							<Col className="d-flex flex-column align-content-stretch">
								<ListGroup>
									{props.info.map((untieInfo, index) => (
										<ListGroup.Item
											key={
												untieInfo.tournament +
												'-' +
												index
											}
											className="d-flex"
										>
											<p>{untieInfo.team}</p>
											<FontAwesomeIcon
												onClick={() =>
													props.rankTeam(
														untieInfo,
														index,
														'down',
														props.indexTournament
													)
												}
												icon={faArrowDown}
											/>
											<FontAwesomeIcon
												onClick={() =>
													props.rankTeam(
														untieInfo,
														index,
														'up',
														props.indexTournament
													)
												}
												icon={faArrowUp}
											/>
										</ListGroup.Item>
									))}
								</ListGroup>
							</Col>
						</Row>
						<Row className="p-2">
							<Col>
								<Button
									onClick={() =>
										props.saveRank(props.indexTournament)
									}
								>
									Save
								</Button>
							</Col>
							<Col>
								<Button>Cancel</Button>
							</Col>
						</Row>
					</Col>
				</Card.Body>
			</Card>
		);
	}
	return (
		<Card style={{ width: '18rem' }} className="mx-2 text-center">
			<Card.Body
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					cursor: 'pointer'
				}}
			>
				<Col>
					<Card.Title className="p-2">
						{props.info[0].tournament}
					</Card.Title>
					<Row className="p-2">
						<Col className="d-flex flex-column align-content-stretch">
							<ListGroup>
								{props.info.map(untieInfo => (
									<ListGroup.Item className="d-flex">
										<p>{untieInfo.team}</p>
									</ListGroup.Item>
								))}
							</ListGroup>
						</Col>
					</Row>
					<Row className="p-2">
						<Col>
							<Button>Save</Button>
						</Col>
						<Col>
							<Button>Cancel</Button>
						</Col>
					</Row>
				</Col>
			</Card.Body>
		</Card>
	);
}
